@charset "utf-8";
/**
   _____                 _     _        
  / ____|               | |   | |       
 | |        __ _   ___  | |_  | |   ___ 
 | |       / _` | / __| | __| | |  / _ \
 | |____  | (_| | \__ \ | |_  | | |  __/
  \_____|  \__,_| |___/  \__| |_|  \___|
 ----------------------------------------
 * Castle's Theme Color Stylesheet
 * Last Update: 2021/04/22 [0.9.7]
 * Author: ohmyga (https://ohmyga.cn)
 * GitHub: https://github.com/bakaomg/castle-Typecho-Theme/
 * LICENSE: GPL V3 (https://www.gnu.org/licenses/gpl-3.0.html)
 **/
/* ==================== 卡片透明 ==================== */
/* ========== 透明 10% ========== */
.moe-card-transparent-10 #moe-post-list .mdui-card,
.moe-card-transparent-10 .moe-post-card,
.moe-card-transparent-10 .moe-comment-card:not(.moe-comment-card-opaque),
.moe-card-transparent-10 .moe-comments-list-card,
.moe-card-transparent-10 .moe-archive-card {
  background-color: rgba(255, 255, 255, 0.9);
}

.mdui-theme-layout-dark .moe-card-transparent-10 #moe-post-list .mdui-card,
.mdui-theme-layout-dark .moe-card-transparent-10 .moe-post-card,
.mdui-theme-layout-dark
  .moe-card-transparent-10
  .moe-comment-card:not(.moe-comment-card-opaque),
.mdui-theme-layout-dark .moe-card-transparent-10 .moe-comments-list-card,
.mdui-theme-layout-dark .moe-card-transparent-10 .moe-archive-card {
  background-color: rgba(66, 66, 66, 0.9);
}

/* ========== 透明 20% ========== */
.moe-card-transparent-20 #moe-post-list .mdui-card,
.moe-card-transparent-20 .moe-post-card,
.moe-card-transparent-20 .moe-comment-card:not(.moe-comment-card-opaque),
.moe-card-transparent-20 .moe-comments-list-card,
.moe-card-transparent-20 .moe-archive-card {
  background-color: rgba(255, 255, 255, 0.8);
}

.mdui-theme-layout-dark .moe-card-transparent-20 #moe-post-list .mdui-card,
.mdui-theme-layout-dark .moe-card-transparent-20 .moe-post-card,
.mdui-theme-layout-dark
  .moe-card-transparent-20
  .moe-comment-card:not(.moe-comment-card-opaque),
.mdui-theme-layout-dark .moe-card-transparent-20 .moe-comments-list-card,
.mdui-theme-layout-dark .moe-card-transparent-20 .moe-archive-card {
  background-color: rgba(66, 66, 66, 0.8);
}

/* ========== 透明 30% ========== */
.moe-card-transparent-30 #moe-post-list .mdui-card,
.moe-card-transparent-30 .moe-post-card,
.moe-card-transparent-30 .moe-comment-card:not(.moe-comment-card-opaque),
.moe-card-transparent-30 .moe-comments-list-card,
.moe-card-transparent-30 .moe-archive-card {
  background-color: rgba(255, 255, 255, 0.7);
}

.mdui-theme-layout-dark .moe-card-transparent-30 #moe-post-list .mdui-card,
.mdui-theme-layout-dark .moe-card-transparent-30 .moe-post-card,
.mdui-theme-layout-dark
  .moe-card-transparent-30
  .moe-comment-card:not(.moe-comment-card-opaque),
.mdui-theme-layout-dark .moe-card-transparent-30 .moe-comments-list-card,
.mdui-theme-layout-dark .moe-card-transparent-30 .moe-archive-card {
  background-color: rgba(66, 66, 66, 0.7);
}

/* ==================== 目录树配色 ==================== */
@media (min-width: 1024px) {
  .moe-toc-transparent-10 {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }

  .mdui-theme-layout-dark .moe-toc-transparent-10 {
    background-color: rgba(66, 66, 66, 0.9) !important;
  }

  .moe-toc-transparent-20 {
    background-color: rgba(255, 255, 255, 0.8) !important;
  }

  .mdui-theme-layout-dark .moe-toc-transparent-20 {
    background-color: rgba(66, 66, 66, 0.8) !important;
  }

  .moe-toc-transparent-30 {
    background-color: rgba(255, 255, 255, 0.7) !important;
  }

  .mdui-theme-layout-dark .moe-toc-transparent-30 {
    background-color: rgba(66, 66, 66, 0.7) !important;
  }
}

/* ==================== 主题配色 ==================== */
/* ========== Red ========== */
/* ===== Theme =====*/
.mdui-theme-primary-red .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-red .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-red .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-red .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-red .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-red .moe-post-card .moe-card-content h6:before {
  color: #f44336;
}

.mdui-theme-primary-red .moe-post-card .moe-card-content h3,
.mdui-theme-primary-red .moe-post-card .moe-card-content h4,
.mdui-theme-primary-red .moe-post-card .moe-card-content h5,
.mdui-theme-primary-red .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #f44336;
}

.mdui-theme-primary-red #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #f44336 !important;
}

.mdui-theme-primary-red #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-red #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #f44336 !important;
}

.mdui-theme-primary-red #nprogress .bar {
  background: #f44336 !important;
}

.mdui-theme-primary-red #nprogress .peg {
  box-shadow: 0 0 10px #f44336, 0 0 5px #f44336 !important;
}

.mdui-theme-primary-red #nprogress .spinner-icon {
  border-top-color: #f44336 !important;
  border-left-color: #f44336 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-red
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-red #moe-post-list .moe-archive-top-card main i {
  color: #ff5252;
}

.mdui-theme-accent-red
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #ff5252;
}

.mdui-theme-accent-red .moe-post-card .moe-card-content .moe-post-card-copy {
  border-left: 4px solid #ff5252;
}
/* ========== Red END ========== */

/* ========== Pink ========== */
/* ===== Theme =====*/
.mdui-theme-primary-pink .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-pink .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-pink .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-pink .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-pink .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-pink .moe-post-card .moe-card-content h6:before {
  color: #e91e63;
}

.mdui-theme-primary-pink .moe-post-card .moe-card-content h3,
.mdui-theme-primary-pink .moe-post-card .moe-card-content h4,
.mdui-theme-primary-pink .moe-post-card .moe-card-content h5,
.mdui-theme-primary-pink .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #e91e63;
}

.mdui-theme-primary-pink #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #e91e63 !important;
}

.mdui-theme-primary-pink #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-pink #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #e91e63 !important;
}

.mdui-theme-primary-pink #nprogress .bar {
  background: #e91e63 !important;
}

.mdui-theme-primary-pink #nprogress .peg {
  box-shadow: 0 0 10px #e91e63, 0 0 5px#e91e63 !important;
}

.mdui-theme-primary-pink #nprogress .spinner-icon {
  border-top-color: #e91e63 !important;
  border-left-color: #e91e63 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-pink
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-pink #moe-post-list .moe-archive-top-card main i {
  color: #ff4081;
}

.mdui-theme-accent-pink
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #ff4081;
}

.mdui-theme-accent-pink .moe-post-card .moe-card-content .moe-post-card-copy {
  border-left: 4px solid #ff4081;
}
/* ========== Pink END ========== */

/* ========== Purple ========== */
/* ===== Theme =====*/
.mdui-theme-primary-purple .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-purple .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-purple .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-purple .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-purple .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-purple .moe-post-card .moe-card-content h6:before {
  color: #9c27b0;
}

.mdui-theme-primary-purple .moe-post-card .moe-card-content h3,
.mdui-theme-primary-purple .moe-post-card .moe-card-content h4,
.mdui-theme-primary-purple .moe-post-card .moe-card-content h5,
.mdui-theme-primary-purple .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #9c27b0;
}

.mdui-theme-primary-purple #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #9c27b0 !important;
}

.mdui-theme-primary-purple #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-purple #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #9c27b0 !important;
}

.mdui-theme-primary-purple #nprogress .bar {
  background: #9c27b0 !important;
}

.mdui-theme-primary-purple #nprogress .peg {
  box-shadow: 0 0 10px #9c27b0, 0 0 5px#9c27b0 !important;
}

.mdui-theme-primary-purple #nprogress .spinner-icon {
  border-top-color: #9c27b0 !important;
  border-left-color: #9c27b0 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-purple
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-purple #moe-post-list .moe-archive-top-card main i {
  color: #ea80fc;
}

.mdui-theme-accent-purple
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #ea80fc;
}

.mdui-theme-accent-purple .moe-post-card .moe-card-content .moe-post-card-copy {
  border-left: 4px solid #ea80fc;
}
/* ========== Purple END ========== */

/* ========== Deep Purple ========== */
/* ===== Theme =====*/
.mdui-theme-primary-deep-purple .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-deep-purple .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-deep-purple .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-deep-purple .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-deep-purple .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-deep-purple .moe-post-card .moe-card-content h6:before {
  color: #673ab7;
}

.mdui-theme-primary-deep-purple .moe-post-card .moe-card-content h3,
.mdui-theme-primary-deep-purple .moe-post-card .moe-card-content h4,
.mdui-theme-primary-deep-purple .moe-post-card .moe-card-content h5,
.mdui-theme-primary-deep-purple .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #673ab7;
}

.mdui-theme-primary-deep-purple #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #673ab7 !important;
}

.mdui-theme-primary-deep-purple
  #toc-sidebar
  .moe-toc-main
  .is-active-link::before,
.mdui-theme-primary-deep-purple
  #toc-sidebar
  .moe-toc-main
  a.toc-link:hover::before {
  background-color: #673ab7 !important;
}

.mdui-theme-primary-deep-purple #nprogress .bar {
  background: #673ab7 !important;
}

.mdui-theme-primary-deep-purple #nprogress .peg {
  box-shadow: 0 0 10px #673ab7, 0 0 5px#673ab7 !important;
}

.mdui-theme-primary-deep-purple #nprogress .spinner-icon {
  border-top-color: #673ab7 !important;
  border-left-color: #673ab7 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-deep-purple
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-deep-purple #moe-post-list .moe-archive-top-card main i {
  color: #b388ff;
}

.mdui-theme-accent-deep-purple
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #b388ff;
}

.mdui-theme-accent-deep-purple
  .moe-post-card
  .moe-card-content
  .moe-post-card-copy {
  border-left: 4px solid #b388ff;
}
/* ========== Deep Purple END ========== */

/* ========== Indigo ========== */
/* ===== Theme =====*/
.mdui-theme-primary-indigo .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-indigo .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-indigo .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-indigo .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-indigo .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-indigo .moe-post-card .moe-card-content h6:before {
  color: #3f51b5;
}

.mdui-theme-primary-indigo .moe-post-card .moe-card-content h3,
.mdui-theme-primary-indigo .moe-post-card .moe-card-content h4,
.mdui-theme-primary-indigo .moe-post-card .moe-card-content h5,
.mdui-theme-primary-indigo .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #3f51b5;
}

.mdui-theme-primary-indigo #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #3f51b5 !important;
}

.mdui-theme-primary-indigo #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-indigo #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #3f51b5 !important;
}

.mdui-theme-primary-indigo #nprogress .bar {
  background: #3f51b5 !important;
}

.mdui-theme-primary-indigo #nprogress .peg {
  box-shadow: 0 0 10px #3f51b5, 0 0 5px #3f51b5 !important;
}

.mdui-theme-primary-indigo #nprogress .spinner-icon {
  border-top-color: #3f51b5 !important;
  border-left-color: #3f51b5 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-indigo
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-indigo #moe-post-list .moe-archive-top-card main i {
  color: #8c9eff;
}

.mdui-theme-accent-indigo
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #8c9eff;
}

.mdui-theme-accent-indigo .moe-post-card .moe-card-content .moe-post-card-copy {
  border-left: 4px solid #8c9eff;
}
/* ========== Indigo END ========== */

/* ========== Blue ========== */
/* ===== Theme =====*/
.mdui-theme-primary-blue .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-blue .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-blue .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-blue .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-blue .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-blue .moe-post-card .moe-card-content h6:before {
  color: #2196f3;
}

.mdui-theme-primary-blue .moe-post-card .moe-card-content h3,
.mdui-theme-primary-blue .moe-post-card .moe-card-content h4,
.mdui-theme-primary-blue .moe-post-card .moe-card-content h5,
.mdui-theme-primary-blue .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #2196f3;
}

.mdui-theme-primary-blue #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #2196f3 !important;
}

.mdui-theme-primary-blue #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-blue #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #2196f3 !important;
}

.mdui-theme-primary-blue #nprogress .bar {
  background: #2196f3 !important;
}

.mdui-theme-primary-blue #nprogress .peg {
  box-shadow: 0 0 10px #2196f3, 0 0 5px #2196f3 !important;
}

.mdui-theme-primary-blue #nprogress .spinner-icon {
  border-top-color: #2196f3 !important;
  border-left-color: #2196f3 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-blue
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-blue #moe-post-list .moe-archive-top-card main i {
  color: #448aff;
}

.mdui-theme-accent-blue
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #448aff;
}

.mdui-theme-accent-blue .moe-post-card .moe-card-content .moe-post-card-copy {
  border-left: 4px solid #448aff;
}
/* ========== Blue END ========== */

/* ========== Light Blue ========== */
/* ===== Theme =====*/
.mdui-theme-primary-light-blue .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-light-blue .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-light-blue .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-light-blue .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-light-blue .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-light-blue .moe-post-card .moe-card-content h6:before {
  color: #03a9f4;
}

.mdui-theme-primary-light-blue .moe-post-card .moe-card-content h3,
.mdui-theme-primary-light-blue .moe-post-card .moe-card-content h4,
.mdui-theme-primary-light-blue .moe-post-card .moe-card-content h5,
.mdui-theme-primary-light-blue .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #03a9f4;
}

.mdui-theme-primary-light-blue #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #03a9f4 !important;
}

.mdui-theme-primary-light-blue
  #toc-sidebar
  .moe-toc-main
  .is-active-link::before,
.mdui-theme-primary-light-blue
  #toc-sidebar
  .moe-toc-main
  a.toc-link:hover::before {
  background-color: #03a9f4 !important;
}

.mdui-theme-primary-light-blue #nprogress .bar {
  background: #03a9f4 !important;
}

.mdui-theme-primary-light-blue #nprogress .peg {
  box-shadow: 0 0 10px #03a9f4, 0 0 5px #03a9f4 !important;
}

.mdui-theme-primary-light-blue #nprogress .spinner-icon {
  border-top-color: #03a9f4 !important;
  border-left-color: #03a9f4 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-light-blue
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-light-blue #moe-post-list .moe-archive-top-card main i {
  color: #40c4ff;
}

.mdui-theme-accent-light-blue
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #40c4ff;
}

.mdui-theme-accent-light-blue
  .moe-post-card
  .moe-card-content
  .moe-post-card-copy {
  border-left: 4px solid #40c4ff;
}
/* ========== Light Blue END ========== */

/* ========== Cyan ========== */
/* ===== Theme =====*/
.mdui-theme-primary-cyan .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-cyan .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-cyan .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-cyan .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-cyan .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-cyan .moe-post-card .moe-card-content h6:before {
  color: #00bcd4;
}

.mdui-theme-primary-cyan .moe-post-card .moe-card-content h3,
.mdui-theme-primary-cyan .moe-post-card .moe-card-content h4,
.mdui-theme-primary-cyan .moe-post-card .moe-card-content h5,
.mdui-theme-primary-cyan .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #00bcd4;
}

.mdui-theme-primary-cyan #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #00bcd4 !important;
}

.mdui-theme-primary-cyan #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-cyan #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #00bcd4 !important;
}

.mdui-theme-primary-cyan #nprogress .bar {
  background: #00bcd4 !important;
}

.mdui-theme-primary-cyan #nprogress .peg {
  box-shadow: 0 0 10px #00bcd4, 0 0 5px#00bcd4 !important;
}

.mdui-theme-primary-cyan #nprogress .spinner-icon {
  border-top-color: #00bcd4 !important;
  border-left-color: #00bcd4 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-cyan
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-cyan #moe-post-list .moe-archive-top-card main i {
  color: #00b8d4;
}

.mdui-theme-accent-cyan
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #00b8d4;
}

.mdui-theme-accent-cyan .moe-post-card .moe-card-content .moe-post-card-copy {
  border-left: 4px solid #00b8d4;
}
/* ========== Cyan END ========== */

/* ========== Teal ========== */
/* ===== Theme =====*/
.mdui-theme-primary-teal .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-teal .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-teal .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-teal .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-teal .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-teal .moe-post-card .moe-card-content h6:before {
  color: #009688;
}

.mdui-theme-primary-teal .moe-post-card .moe-card-content h3,
.mdui-theme-primary-teal .moe-post-card .moe-card-content h4,
.mdui-theme-primary-teal .moe-post-card .moe-card-content h5,
.mdui-theme-primary-teal .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #009688;
}

.mdui-theme-primary-teal #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #009688 !important;
}

.mdui-theme-primary-teal #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-teal #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #009688 !important;
}

.mdui-theme-primary-teal #nprogress .bar {
  background: #009688 !important;
}

.mdui-theme-primary-teal #nprogress .peg {
  box-shadow: 0 0 10px #009688, 0 0 5px #009688 !important;
}

.mdui-theme-primary-teal #nprogress .spinner-icon {
  border-top-color: #009688 !important;
  border-left-color: #009688 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-teal
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-teal #moe-post-list .moe-archive-top-card main i {
  color: #00bfa5;
}

.mdui-theme-accent-teal
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #00bfa5;
}

.mdui-theme-accent-teal .moe-post-card .moe-card-content .moe-post-card-copy {
  border-left: 4px solid #00bfa5;
}
/* ========== Teal END ========== */

/* ========== Green ========== */
/* ===== Theme =====*/
.mdui-theme-primary-green .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-green .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-green .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-green .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-green .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-green .moe-post-card .moe-card-content h6:before {
  color: #4caf50;
}

.mdui-theme-primary-green .moe-post-card .moe-card-content h3,
.mdui-theme-primary-green .moe-post-card .moe-card-content h4,
.mdui-theme-primary-green .moe-post-card .moe-card-content h5,
.mdui-theme-primary-green .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #4caf50;
}

.mdui-theme-primary-green #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #4caf50 !important;
}

.mdui-theme-primary-green #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-green #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #4caf50 !important;
}

.mdui-theme-primary-green #nprogress .bar {
  background: #4caf50 !important;
}

.mdui-theme-primary-green #nprogress .peg {
  box-shadow: 0 0 10px #4caf50, 0 0 5px #4caf50 !important;
}

.mdui-theme-primary-green #nprogress .spinner-icon {
  border-top-color: #4caf50 !important;
  border-left-color: #4caf50 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-green
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-green #moe-post-list .moe-archive-top-card main i {
  color: #69f0ae;
}

.mdui-theme-accent-green
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #69f0ae;
}

.mdui-theme-accent-green .moe-post-card .moe-card-content .moe-post-card-copy {
  border-left: 4px solid #69f0ae;
}
/* ========== Green END ========== */

/* ========== Light Green ========== */
/* ===== Theme =====*/
.mdui-theme-primary-light-green .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-light-green .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-light-green .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-light-green .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-light-green .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-light-green .moe-post-card .moe-card-content h6:before {
  color: #8bc34a;
}

.mdui-theme-primary-light-green .moe-post-card .moe-card-content h3,
.mdui-theme-primary-light-green .moe-post-card .moe-card-content h4,
.mdui-theme-primary-light-green .moe-post-card .moe-card-content h5,
.mdui-theme-primary-light-green .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #8bc34a;
}

.mdui-theme-primary-light-green #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #8bc34a !important;
}

.mdui-theme-primary-light-green
  #toc-sidebar
  .moe-toc-main
  .is-active-link::before,
.mdui-theme-primary-light-green
  #toc-sidebar
  .moe-toc-main
  a.toc-link:hover::before {
  background-color: #8bc34a !important;
}

.mdui-theme-primary-light-green #nprogress .bar {
  background: #8bc34a !important;
}

.mdui-theme-primary-light-green #nprogress .peg {
  box-shadow: 0 0 10px #8bc34a, 0 0 5px #8bc34a !important;
}

.mdui-theme-primary-light-green #nprogress .spinner-icon {
  border-top-color: #8bc34a !important;
  border-left-color: #8bc34a !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-light-green
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-light-green #moe-post-list .moe-archive-top-card main i {
  color: #64dd17;
}

.mdui-theme-accent-light-green
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #64dd17;
}

.mdui-theme-accent-light-green
  .moe-post-card
  .moe-card-content
  .moe-post-card-copy {
  border-left: 4px solid #64dd17;
}
/* ========== Light Green END ========== */

/* ========== Lime ========== */
/* ===== Theme =====*/
.mdui-theme-primary-lime .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-lime .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-lime .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-lime .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-lime .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-lime .moe-post-card .moe-card-content h6:before {
  color: #cddc39;
}

.mdui-theme-primary-lime .moe-post-card .moe-card-content h3,
.mdui-theme-primary-lime .moe-post-card .moe-card-content h4,
.mdui-theme-primary-lime .moe-post-card .moe-card-content h5,
.mdui-theme-primary-lime .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #cddc39;
}

.mdui-theme-primary-lime #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #cddc39 !important;
}

.mdui-theme-primary-lime #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-lime #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #cddc39 !important;
}

.mdui-theme-primary-lime #nprogress .bar {
  background: #cddc39 !important;
}

.mdui-theme-primary-lime #nprogress .peg {
  box-shadow: 0 0 10px #cddc39, 0 0 5px #cddc39 !important;
}

.mdui-theme-primary-lime #nprogress .spinner-icon {
  border-top-color: #cddc39 !important;
  border-left-color: #cddc39 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-lime
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-lime #moe-post-list .moe-archive-top-card main i {
  color: #aeea00;
}

.mdui-theme-accent-lime
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #aeea00;
}

.mdui-theme-accent-lime .moe-post-card .moe-card-content .moe-post-card-copy {
  border-left: 4px solid #aeea00;
}
/* ========== Lime END ========== */

/* ========== Yellow ========== */
/* ===== Theme =====*/
.mdui-theme-primary-yellow .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-yellow .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-yellow .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-yellow .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-yellow .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-yellow .moe-post-card .moe-card-content h6:before {
  color: #ffeb3b;
}

.mdui-theme-primary-yellow .moe-post-card .moe-card-content h3,
.mdui-theme-primary-yellow .moe-post-card .moe-card-content h4,
.mdui-theme-primary-yellow .moe-post-card .moe-card-content h5,
.mdui-theme-primary-yellow .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #ffeb3b;
}

.mdui-theme-primary-yellow #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #ffeb3b !important;
}

.mdui-theme-primary-yellow #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-yellow #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #ffeb3b !important;
}

.mdui-theme-primary-yellow #nprogress .bar {
  background: #ffeb3b !important;
}

.mdui-theme-primary-yellow #nprogress .peg {
  box-shadow: 0 0 10px #ffeb3b, 0 0 5px #ffeb3b !important;
}

.mdui-theme-primary-yellow #nprogress .spinner-icon {
  border-top-color: #ffeb3b !important;
  border-left-color: #ffeb3b !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-yellow
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-yellow #moe-post-list .moe-archive-top-card main i {
  color: #ffd600;
}

.mdui-theme-accent-yellow
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #ffd600;
}

.mdui-theme-accent-yellow .moe-post-card .moe-card-content .moe-post-card-copy {
  border-left: 4px solid #ffd600;
}
/* ========== Yellow END ========== */

/* ========== Amber ========== */
/* ===== Theme =====*/
.mdui-theme-primary-amber .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-amber .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-amber .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-amber .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-amber .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-amber .moe-post-card .moe-card-content h6:before {
  color: #ffc107;
}

.mdui-theme-primary-amber .moe-post-card .moe-card-content h3,
.mdui-theme-primary-amber .moe-post-card .moe-card-content h4,
.mdui-theme-primary-amber .moe-post-card .moe-card-content h5,
.mdui-theme-primary-amber .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #ffc107;
}

.mdui-theme-primary-amber #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #ffc107 !important;
}

.mdui-theme-primary-amber #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-amber #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #ffc107 !important;
}

.mdui-theme-primary-amber #nprogress .bar {
  background: #ffc107 !important;
}

.mdui-theme-primary-amber #nprogress .peg {
  box-shadow: 0 0 10px #ffc107, 0 0 5px #ffc107 !important;
}

.mdui-theme-primary-amber #nprogress .spinner-icon {
  border-top-color: #ffc107 !important;
  border-left-color: #ffc107 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-amber
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-amber #moe-post-list .moe-archive-top-card main i {
  color: #ffd740;
}

.mdui-theme-accent-amber
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #ffd740;
}

.mdui-theme-accent-amber .moe-post-card .moe-card-content .moe-post-card-copy {
  border-left: 4px solid #ffd740;
}
/* ========== Amber END ========== */

/* ========== Orange ========== */
/* ===== Theme =====*/
.mdui-theme-primary-orange .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-orange .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-orange .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-orange .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-orange .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-orange .moe-post-card .moe-card-content h6:before {
  color: #ff9800;
}

.mdui-theme-primary-orange .moe-post-card .moe-card-content h3,
.mdui-theme-primary-orange .moe-post-card .moe-card-content h4,
.mdui-theme-primary-orange .moe-post-card .moe-card-content h5,
.mdui-theme-primary-orange .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #ff9800;
}

.mdui-theme-primary-orange #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #ff9800 !important;
}

.mdui-theme-primary-orange #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-orange #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #ff9800 !important;
}

.mdui-theme-primary-orange #nprogress .bar {
  background: #ff9800 !important;
}

.mdui-theme-primary-orange #nprogress .peg {
  box-shadow: 0 0 10px #ff9800, 0 0 5px #ff9800 !important;
}

.mdui-theme-primary-orange #nprogress .spinner-icon {
  border-top-color: #ff9800 !important;
  border-left-color: #ff9800 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-orange
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-orange #moe-post-list .moe-archive-top-card main i {
  color: #ffab40;
}

.mdui-theme-accent-orange
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #ffab40;
}

.mdui-theme-accent-orange .moe-post-card .moe-card-content .moe-post-card-copy {
  border-left: 4px solid #ffab40;
}
/* ========== Orange END ========== */

/* ========== Deep Orange ========== */
/* ===== Theme =====*/
.mdui-theme-primary-deep-orange .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-deep-orange .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-deep-orange .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-deep-orange .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-deep-orange .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-deep-orange .moe-post-card .moe-card-content h6:before {
  color: #ff5722;
}

.mdui-theme-primary-deep-orange .moe-post-card .moe-card-content h3,
.mdui-theme-primary-deep-orange .moe-post-card .moe-card-content h4,
.mdui-theme-primary-deep-orange .moe-post-card .moe-card-content h5,
.mdui-theme-primary-deep-orange .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #ff5722;
}

.mdui-theme-primary-deep-orange #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #ff5722 !important;
}

.mdui-theme-primary-deep-orange
  #toc-sidebar
  .moe-toc-main
  .is-active-link::before,
.mdui-theme-primary-deep-orange
  #toc-sidebar
  .moe-toc-main
  a.toc-link:hover::before {
  background-color: #ff5722 !important;
}

.mdui-theme-primary-deep-orange #nprogress .bar {
  background: #ff5722 !important;
}

.mdui-theme-primary-deep-orange #nprogress .peg {
  box-shadow: 0 0 10px #ff5722, 0 0 5px #ff5722 !important;
}

.mdui-theme-primary-deep-orange #nprogress .spinner-icon {
  border-top-color: #ff5722 !important;
  border-left-color: #ff5722 !important;
}

/* ===== Accent ===== */
.mdui-theme-accent-deep-orange
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]),
.mdui-theme-accent-deep-orange #moe-post-list .moe-archive-top-card main i {
  color: #ff6e40;
}

.mdui-theme-accent-deep-orange
  .moe-post-card
  .moe-card-content
  a:not([data-baguettebox]):before {
  background-color: #ff6e40;
}

.mdui-theme-accent-deep-orange
  .moe-post-card
  .moe-card-content
  .moe-post-card-copy {
  border-left: 4px solid #ff6e40;
}
/* ========== Deep Orange END ========== */

/* ========== Brown ========== */
/* ===== Theme =====*/
.mdui-theme-primary-brown .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-brown .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-brown .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-brown .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-brown .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-brown .moe-post-card .moe-card-content h6:before {
  color: #795548;
}

.mdui-theme-primary-brown .moe-post-card .moe-card-content h3,
.mdui-theme-primary-brown .moe-post-card .moe-card-content h4,
.mdui-theme-primary-brown .moe-post-card .moe-card-content h5,
.mdui-theme-primary-brown .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #795548;
}

.mdui-theme-primary-brown #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #795548 !important;
}

.mdui-theme-primary-brown #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-brown #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #795548 !important;
}

.mdui-theme-primary-brown #nprogress .bar {
  background: #795548 !important;
}

.mdui-theme-primary-brown #nprogress .peg {
  box-shadow: 0 0 10px #795548, 0 0 5px #795548 !important;
}

.mdui-theme-primary-brown #nprogress .spinner-icon {
  border-top-color: #795548 !important;
  border-left-color: #795548 !important;
}
/* ========== Brown END ========== */

/* ========== Grey ========== */
/* ===== Theme =====*/
.mdui-theme-primary-grey .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-grey .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-grey .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-grey .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-grey .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-grey .moe-post-card .moe-card-content h6:before {
  color: #9e9e9e;
}

.mdui-theme-primary-grey .moe-post-card .moe-card-content h3,
.mdui-theme-primary-grey .moe-post-card .moe-card-content h4,
.mdui-theme-primary-grey .moe-post-card .moe-card-content h5,
.mdui-theme-primary-grey .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #9e9e9e;
}

.mdui-theme-primary-grey #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #9e9e9e !important;
}

.mdui-theme-primary-grey #toc-sidebar .moe-toc-main .is-active-link::before,
.mdui-theme-primary-grey #toc-sidebar .moe-toc-main a.toc-link:hover::before {
  background-color: #9e9e9e !important;
}

.mdui-theme-primary-grey #nprogress .bar {
  background: #9e9e9e !important;
}

.mdui-theme-primary-grey #nprogress .peg {
  box-shadow: 0 0 10px #9e9e9e, 0 0 5px #9e9e9e !important;
}

.mdui-theme-primary-grey #nprogress .spinner-icon {
  border-top-color: #9e9e9e !important;
  border-left-color: #9e9e9e !important;
}
/* ========== Grey END ========== */

/* ========== Blue Grey ========== */
/* ===== Theme =====*/
.mdui-theme-primary-blue-grey .moe-post-card .moe-card-content h1:before,
.mdui-theme-primary-blue-grey .moe-post-card .moe-card-content h2:before,
.mdui-theme-primary-blue-grey .moe-post-card .moe-card-content h3:before,
.mdui-theme-primary-blue-grey .moe-post-card .moe-card-content h4:before,
.mdui-theme-primary-blue-grey .moe-post-card .moe-card-content h5:before,
.mdui-theme-primary-blue-grey .moe-post-card .moe-card-content h6:before {
  color: #607d8b;
}

.mdui-theme-primary-blue-grey .moe-post-card .moe-card-content h3,
.mdui-theme-primary-blue-grey .moe-post-card .moe-card-content h4,
.mdui-theme-primary-blue-grey .moe-post-card .moe-card-content h5,
.mdui-theme-primary-blue-grey .moe-post-card .moe-card-content h6 {
  border-left: 4px solid #607d8b;
}

.mdui-theme-primary-blue-grey #toc-sidebar .moe-toc-main a.toc-link:hover {
  color: #607d8b !important;
}

.mdui-theme-primary-blue-grey
  #toc-sidebar
  .moe-toc-main
  .is-active-link::before,
.mdui-theme-primary-blue-grey
  #toc-sidebar
  .moe-toc-main
  a.toc-link:hover::before {
  background-color: #607d8b !important;
}

.mdui-theme-primary-blue-grey #nprogress .bar {
  background: #607d8b !important;
}

.mdui-theme-primary-blue-grey #nprogress .peg {
  box-shadow: 0 0 10px #607d8b, 0 0 5px #607d8b !important;
}

.mdui-theme-primary-blue-grey #nprogress .spinner-icon {
  border-top-color: #607d8b !important;
  border-left-color: #607d8b !important;
}
/* ========== Blue Grey END ========== */
